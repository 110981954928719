import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import "./Login.css";

const Login = ({ setIsAuthenticated }: { setIsAuthenticated: React.Dispatch<React.SetStateAction<boolean>> }) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();

  // Specify the type for the event 'e'
  const handleLogin = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    
    //const defaultEmail = "geo@tech.com";
    //const defaultPassword = "admin@123";
    const defaultEmail = "demo@brlps.in";
    const defaultPassword = "brlps";
    
    if (email === defaultEmail && password === defaultPassword) {
      setIsAuthenticated(true);
      localStorage.setItem("isAuthenticated", "true"); // Store authentication status

      
      navigate(" "); // Redirect to home page after authentication
    } else {
      alert("Invalid email or password");
    }
  };

  return (
    <div className="container">
      <div className="heading">Sign In</div>
      <form onSubmit={handleLogin} className="form">
        <input
          required
          className="input"
          type="email"
          name="email"
          placeholder="E-mail"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <input
          required
          className="input"
          type="password"
          name="password"
          placeholder="Password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        <input className="login-button" type="submit" value="Sign In" />
      </form>
    </div>
  );
};

export default Login;
