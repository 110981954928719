// src/utils/handleSpeechSynthesis.js
let currentUtterance = null;
let isPaused = false;

function handleSpeechSynthesis(text, language = 'en-US') {
    const synth = window.speechSynthesis;
    let voices = synth.getVoices();

    // const createUtterance = () => {
    //     let suitableVoice = voices.find(voice => (
    //         voice.lang.startsWith(language) && voice.name.toLowerCase().includes('female')
    //     ));

    //     if (!suitableVoice) {
    //         suitableVoice = voices.find(voice => voice.lang.startsWith(language) && voice.gender === 'female');
    //     }

    //     if (!suitableVoice) {
    //         suitableVoice = voices.find(voice => voice.lang.startsWith(language));
    //     }

    //     const utterance = new SpeechSynthesisUtterance(text);
    //     utterance.voice = suitableVoice || voices[0]; // Fallback to the first available voice
    //     return utterance;
    // };
    const createUtterance = () => {
        // Check if 'Lekha' voice is available
        let lekhaVoice = voices.find(voice => voice.voiceURI === 'Lekha' || voice.name === 'Lekha');
    
        // If 'Lekha' voice is found, use it
        if (lekhaVoice) {
            const utterance = new SpeechSynthesisUtterance(text);
            utterance.voice = lekhaVoice;
            return utterance;
        }
    
        // Otherwise, find a suitable voice based on language and gender
        let suitableVoice = voices.find(voice => (
            voice.lang.startsWith(language) && voice.name.toLowerCase().includes('female')
        ));
    
        if (!suitableVoice) {
            suitableVoice = voices.find(voice => voice.lang.startsWith(language) && voice.gender === 'female');
        }
    
        if (!suitableVoice) {
            suitableVoice = voices.find(voice => voice.lang.startsWith(language));
        }
    
        // Create utterance with the suitable voice or fallback to the first available voice
        const utterance = new SpeechSynthesisUtterance(text);
        utterance.voice = suitableVoice || voices[0];
        return utterance;
    };
    

    // if (voices.length > 0) {
    //     return createUtterance();
    // } else {
    //     synth.onvoiceschanged = () => {
    //         voices = synth.getVoices();
    //         return createUtterance();
    //     };

    if (synth.speaking) {
        if (currentUtterance && text === currentUtterance.text) {
            if (isPaused) {
                synth.resume(); // Resume if paused
                isPaused = false;
            } else {
                synth.pause(); // Pause if already speaking
                isPaused = true;
            }
        } else {
            synth.cancel(); // Stop previous utterance
            playSpeech(text); // Start a new utterance
        }
    } else {
        playSpeech(text); // Start new speech if nothing is speaking
    }

    function playSpeech(text) {
        if (voices.length > 0) {
            currentUtterance = createUtterance();
            synth.speak(currentUtterance);
            isPaused = false;

            currentUtterance.onend = () => {
                currentUtterance = null;
            };
        } else {
            synth.onvoiceschanged = () => {
                voices = synth.getVoices();
                currentUtterance = createUtterance();
                synth.speak(currentUtterance);
                isPaused = false;

                currentUtterance.onend = () => {
                    currentUtterance = null;
                };
            };
        }
    }

    }

export default handleSpeechSynthesis;