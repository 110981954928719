import React, { useState,useEffect } from "react";
import "./App.css";
import {  Routes, Route, Navigate } from "react-router-dom";
import Login from "./pages/Login/Login";
import Home from "./pages/home/Home";
import Chat from "./pages/chat/Chat";
import { BrowserRouter as Router } from "react-router-dom";
import StaticQuestions from './components/queryAns/QueryAns';

function App() {
  const [isChatbotButtonClicked, setIsChatbotButtonClicked] = useState(false);
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  useEffect(() => {
    const authStatus = localStorage.getItem("isAuthenticated");
    if (authStatus === "true") {
      setIsAuthenticated(true);
    }
  }, []);

  return (
    <Router>
      <Routes>
        <Route
          path="/login"
          element={
            isAuthenticated ? (
              <Navigate to="/" />
            ) : (
              <Login setIsAuthenticated={setIsAuthenticated} />
            )
          }
        />
        <Route
          path="/"
          element={
            isAuthenticated ? (
              <Home setIsChatbotButtonClicked={setIsChatbotButtonClicked} />
            ) : (
              <Navigate to="/login" />
            )
          }
        />
        <Route
          path="/chat"
          element={
            isAuthenticated ? (
              <Chat isChatbotButtonClicked={isChatbotButtonClicked} />
            ) : (
              <Navigate to="/chat" />
            )
          }
        />
      </Routes>
    </Router>
  );
};

//   return (
//     <div className="App">
//       <BrowserRouter>
//         <Routes>
// <Route
//   path="/"
//   element={<Home setIsChatbotButtonClicked={setIsChatbotButtonClicked} />}
// />
{/* <Route
  path="/chat"
  element={<Chat isChatbotButtonClicked={isChatbotButtonClicked} />}
/> */}
//         </Routes>
//       </BrowserRouter>
//     </div>
//   );
// }
export default App;





// import React, { useState } from "react";
// import "./App.css";
// import { BrowserRouter, Routes, Route } from "react-router-dom";
// import { Chat, Home } from "./pages";

// function App() {
//   const [isChatbotButtonClicked, setIsChatbotButtonClicked] = useState(false);
//   return (
//     <div className="App">
//       <BrowserRouter>
//         <Routes>
//           <Route
//             path="/"
//             element={
//               <Home setIsChatbotButtonClicked={setIsChatbotButtonClicked} />
//             }
//           />
//           <Route
//             path="/chat"
//             element={<Chat isChatbotButtonClicked={isChatbotButtonClicked} />}
//           />
//         </Routes>
//       </BrowserRouter>
//     </div>
//   );
// }

// export default App;

