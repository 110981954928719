import speak from './handleSpeechSynthesis';

function handleSpeechRecognition() {
    return new Promise((resolve, reject) => {
        let SpeechRecognition = window.webkitSpeechRecognition || window.SpeechRecognition;

        if (!SpeechRecognition) {
            reject("Voice feature is not supported in your browser");
        }

        let recognition = new SpeechRecognition();

        // Set properties for speech recognition
        recognition.continuous = false; // End after user stops speaking
        recognition.interimResults = false; // Return only final result
        recognition.maxAlternatives = 1; // Set the maximum number of alternatives to return

        // Event handling for recognition
        recognition.onresult = (event) => {
            let word = event.results[0][0].transcript;
            console.log("Recognized word:", word);
            speak(word); // Speak the recognized word
            resolve(word);
        };

        recognition.onnomatch = (event) => {
            const errorMessage = "Sorry, we couldn't understand you.";
            speak(errorMessage); // Speak the error message
            reject(errorMessage);
        };

        recognition.onerror = (event) => {
            const errorMessage = `Error occurred in recognition: ${event.error}`;
            speak(errorMessage); // Speak the error message
            reject(errorMessage);
        };

        recognition.onspeechend = () => {
            recognition.stop();
        };

        // Start recognition
        recognition.start();
    });
}

export default handleSpeechRecognition;
