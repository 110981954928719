import { showLoading, showError, setAnswer, setQuery } from "../features/queryAnsSlice";

// const answerEndpoint = "http://127.0.0.1:5000/response";
const answerEndpoint = "https://flask.brlps.in/response";

export const fetchAnswerMiddleware = (query) => {
  console.log("fetchmiddleware called");
  return async (dispatch) => {
    try {
      dispatch(showLoading());

      const resp = await fetch(answerEndpoint, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ "question": query })
      });

      if (resp.ok) {
        const data = await resp.json();
        console.log("data is:", data);
        dispatch(setAnswer(data.response));
      } else {
        const data = await resp.json();
        console.log('data....', data)
        const response = data.response
        const suggestedQuestions1 = data.response_list
        console.log('suggestedQuestions.1..', suggestedQuestions1)
        console.log(typeof(suggestedQuestions1))
        
        // const suggestedQuestions2 = suggestedQuestions1.replace(/'/g, '"');

        // const suggestedQuestions = JSON.parse(suggestedQuestions2)
        // console.log('suggestedQuestions..2.' + suggestedQuestions)
        // console.log(typeof(suggestedQuestions))

        // const dkeys = Object.keys(suggestedQuestions1)
        // console.log('dkeys...' + dkeys)

        const suggestedQuestions = suggestedQuestions1

        // If response status is not 200, handle the error
        const errorMessage = "I did not understand your query,Please reframe your query in the context of Jeevika and its Themes";
        // const suggestedQuestions = [
        //   "Total CLF, VO, SHG?",
        //   "Total CLF, VO, SHG savings accounts?",
        //   "Total DKR sales?",
        //   "Total members in Poultry, Goatry, Diary and Fishery?"
        // ];

        // const errorWithSuggestions = `${response}<br>Suggested questions:<br>${suggestedQuestions.map((q, index) => `<button onclick="handleClick(${index})">${q}</button>`).join('<br>')}`;

        const errorWithSuggestions = response ? (
          suggestedQuestions.length > 0 ? (
            `${response}<br>Suggested questions:<br>${suggestedQuestions.map((q, index) => `<button onclick="handleClick(${index})">${q}</button>`).join('<br>')}`
          ) : (
            `${response}`
          )
        ) : '';


        dispatch(setAnswer(errorWithSuggestions));
        dispatch(showError());

        // Function to handle click on suggested questions
        window.handleClick = async (index) => {
          const clickedQuestion = suggestedQuestions[index];
          dispatch(setQuery(clickedQuestion)); // Update the query container with the clicked question
          dispatch(fetchAnswerMiddleware(clickedQuestion)); // Automatically send the new query
        };
      }

    } catch (err) {
      console.error("Error occurred:", err);
      dispatch(setAnswer(`${err.message}`));
      dispatch(showError());
    }
  };
};  
