import React, { useState, useEffect, useRef } from "react";
import { useAppSelector, useAppDispatch } from "../../app/hooks";
import { botIcon, speakerIcon, userIcon, activeSpeakerIcon } from "../../assets/icons";
import { askDidiLogo } from "../../assets/images";
import handleSpeechSynthesis from "../../utils/handleSpeechSynthesis";
import { utils, writeFile } from 'xlsx';
import Chart from 'chart.js/auto';
import downloadIcon from "../../assets/icons/download.svg";
import LineIcon from "../../assets/icons/line.svg";
import { setQuery, setAnswer, showLoading, showError } from "../../features/queryAnsSlice"; // Adjust the path as needed
import { fetchAnswerMiddleware } from "../../middlewares/fetchAnswerMiddleware"; // Adjust the path as needed
import "./queryAns.css";

const detectLanguage = (text) => {
    const hindiRegex = /[\u0900-\u097F]/;
    return hindiRegex.test(text) ? 'hi-IN' : 'en-US';
};

const stripHtmlTags = (html) => {
    const div = document.createElement("div");
    div.innerHTML = html;
    return div.textContent || div.innerText || "";
};

const isTabularData = (content) => {
    const plainTextContent = stripHtmlTags(content);
    const lines = plainTextContent.split('\n').filter(line => line.trim() !== '');
    return lines.length % 2 === 0 && lines.length > 0; // Simple check for pairs of data lines
};

function QueryAns(props) {
    const [isSpeakerClicked, setIsSpeakerClicked] = useState([]);
    const [contentType, setContentType] = useState('');
    const [isChartVisible, setIsChartVisible] = useState([]);
    const [isClicked, setIsClicked] = useState(false);
    const query = useAppSelector((state) => state.queryAnsReducer.query);
    const answer = useAppSelector((state) => state.queryAnsReducer.ans);
    const dispatch = useAppDispatch();

    const queryAnsWindowRef = useRef(null);
    const chartRef = useRef([]);

    const initialText = `नमस्ते , मैं स्मार्ट दीदी हूं, आपकी Jeevika चैटबॉट। मैं Jeevika और इसके थीम पर डिजिटल सॉल्यूशन से संबंधित प्रश्नों का उत्तर देने के लिए प्रशिक्षित हूं, जिसमें IBCB, वित्तीय समावेशन, कृषि, गैर-कृषि, पशुधन, नौकरियां, HNS, सामाजिक विकास आदि शामिल हैं। यदि आपके पास इन विषयों पर कोई प्रश्न हैं, तो कृपया पूछने में संकोच न करें।`;

    const handleSpeakerClick = (htmlContent, index, contentTypeIS) => {
        try {
            const plainText = stripHtmlTags(htmlContent);
            let detectedLanguage = detectLanguage(plainText);
            let utterance = handleSpeechSynthesis(plainText, detectedLanguage);

            if (!utterance) {
                console.log('No utterance created');
                return;
            }

            const isSpeakerClickedCopy = [...isSpeakerClicked];
            setContentType(contentTypeIS);

            utterance.onstart = () => {
                isSpeakerClickedCopy[index] = true;
                setIsSpeakerClicked([...isSpeakerClickedCopy]);
            };
            utterance.onend = () => {
                isSpeakerClickedCopy[index] = false;
                setIsSpeakerClicked([...isSpeakerClickedCopy]);
            };

            window.speechSynthesis.speak(utterance);
        } catch (error) {
            console.log(error);
        }
    };

    const handleDownload = (content) => {
        const plainTextContent = stripHtmlTags(content);
        downloadAsExcel(plainTextContent);
    };

    const downloadAsExcel = (content) => {
        const lines = content.split('\n').filter(line => line.trim() !== '');
        const worksheetData = [];
        for (let i = 0; i < lines.length; i += 2) {
            if (lines[i + 1]) {
                worksheetData.push({
                    District: lines[i].trim(),
                    Count: lines[i + 1].trim()
                });
            }
        }
        const worksheet = utils.json_to_sheet(worksheetData);
        const workbook = utils.book_new();
        utils.book_append_sheet(workbook, worksheet, 'Answers');
        writeFile(workbook, 'answers.xlsx');
    };

    const handleLineChart = (content, index) => {
        const plainTextContent = stripHtmlTags(content);
        const lines = plainTextContent.split('\n').filter(line => line.trim() !== '');

        if (lines.length % 2 !== 0) {
            console.log("Invalid data format for line chart.");
            return;
        }

        const labels = [];
        const data = [];

        for (let i = 0; i < lines.length; i += 2) {
            labels.push(lines[i].trim());
            data.push(parseInt(lines[i + 1].trim(), 10));
        }

        if (data.length === 0) {
            console.log("No data available for line chart.");
            return;
        }

        const chartData = {
            labels: labels,
            datasets: [
                {
                    label: 'Count',
                    data: data,
                    fill: false,
                    borderColor: 'rgb(75, 192, 192)',
                    tension: 0.5
                }
            ]
        };

        const config = {
            type: 'line',
            data: chartData,
            options: {
                responsive: true, // Make the chart responsive
                maintainAspectRatio: false, // Allow the chart to stretch without maintaining aspect ratio
                plugins: {
                    legend: {
                        position: 'top',
                    },
                    tooltip: {
                        callbacks: {
                            label: function (tooltipItem) {
                                return tooltipItem.label + ': ' + tooltipItem.raw;
                            }
                        }
                    }
                },
                scales: {
                    x: {
                        title: {
                            display: true,
                        },
                        ticks: {
                            autoSkip: false, // Show all labels
                            maxRotation: 90, // Rotate labels if needed
                            minRotation: 45 // Rotate labels if needed
                        }
                    },
                    y: {
                        title: {
                            display: true,
                            text: 'Values'
                        }
                    }
                }
            }
        };

        const isChartVisibleCopy = [...isChartVisible];
        isChartVisibleCopy[index] = !isChartVisibleCopy[index];
        setIsChartVisible([...isChartVisibleCopy]);

        setTimeout(() => {  // Delay to ensure state update reflects in the DOM
            const ctx = document.getElementById(`lineChart-${index}`);
            if (ctx) {
                if (chartRef.current[index]) {
                    chartRef.current[index].destroy();
                }
                chartRef.current[index] = new Chart(ctx, config);
            } else {
                console.log("Canvas context not found");
            }
        }, 0);
    };

    const handleClick = (clickedQuestion) => {
        // Dispatch action to fetch answer for clicked question
        dispatch(setQuery(clickedQuestion));
        dispatch(fetchAnswerMiddleware(clickedQuestion));
    };

    useEffect(() => {
        queryAnsWindowRef.current.scrollTop = queryAnsWindowRef.current.scrollHeight;
    }, [query, answer]);

    const renderSuggestedQuestions = (suggestedQuestions) => {
        return (
            <div className="suggested-questions">
                {suggestedQuestions.map((question, index) => (
                    <button key={index} onClick={() => handleClick(question)} className="suggested-question-btn"
                        style={{ backgroundColor: 'blue', color: 'white' }}>
                        {question}
                    </button>
                ))}
            </div>
        );
    };

    return (
        <div id={props.id} ref={queryAnsWindowRef}>
            {/* Fetch and display query and answer in conversation window */}
            {true && (
                <div className="ans">
                    <img
                        className="bot-icon conversation-profile"
                        src={botIcon}
                        alt={askDidiLogo}
                    />
                    <div className="ans-conversation-txt-container">
                        <img
                            className="speaker"
                            src={isClicked ? activeSpeakerIcon : speakerIcon}
                            alt="speaker"
                            onClick={() => {
                                handleSpeakerClick(initialText, 0, "answer");
                                setIsClicked((prev) => !prev);
                            }}
                        />
                        <p className="conversation-txt">
                            {initialText}
                        </p>
                    </div>
                </div>
            )}
            {/* Render user queries and bot answers */}
            {query.map((question, index) => {
                const ans = answer[index];

                return (
                    <div className="queryAns-container" key={index}>
                        <div className="query">
                            <div className="query-conversation-txt-container">
                                <img
                                    className="speaker"
                                    src={
                                        isSpeakerClicked[index] && contentType === 'query' ? activeSpeakerIcon : speakerIcon
                                    }
                                    alt="speaker"
                                    onClick={() => handleSpeakerClick(question, index, 'query')}
                                />
                                <p className="conversation-txt">{question}</p>
                            </div>
                            <img
                                className="user-icon conversation-profile"
                                src={userIcon}
                                alt={userIcon}
                            />
                        </div>

                        {ans !== undefined ? (
                            <div className="ans">
                                <img
                                    className="bot-icon conversation-profile"
                                    src={botIcon}
                                    alt={askDidiLogo}
                                />
                                <div className="ans-content">
                                    <div className="ans-conversation-txt-container">
                                        <img
                                            className="speaker"
                                            src={
                                                isSpeakerClicked[index] && contentType === 'answer' ? activeSpeakerIcon : speakerIcon
                                            }
                                            alt="speaker"
                                            onClick={() => handleSpeakerClick(ans, index, 'answer')}
                                        />
                                        <div
                                            className="conversation-txt"
                                            dangerouslySetInnerHTML={{ __html: ans }}
                                        />
                                    </div>
                                    <div className="chart-container">
                                        {isTabularData(ans) && (
                                            <>
                                                <button className="download-btn" onClick={() => handleDownload(ans)}>
                                                    <img src={downloadIcon} alt="Download Icon" className="download-icon" />
                                                    <span className="download-text">Download</span>
                                                </button>
                                                <button className="Linechart-btn" onClick={() => handleLineChart(ans, index)}>
                                                    <img src={LineIcon} alt="Line Icon" className="Line-icon" />
                                                    <span className="line-text">Line Graph</span>
                                                </button>
                                            </>
                                        )}
                                        {isChartVisible[index] && (
                                            <canvas id={`lineChart-${index}`} width="400" height="400"></canvas>
                                        )}
                                    </div>
                                    {/* Render suggested questions */}
                                    {ans.includes("Suggested questions:") && renderSuggestedQuestions([
                                    ])}
                                </div>
                            </div>
                        ) : (
                            <div className="ans">
                                <img
                                    className="bot-icon conversation-profile"
                                    src={botIcon}
                                    alt={askDidiLogo}
                                />
                                <div className="ans-conversation-txt-container">
                                    <p className="conversation-txt">
                                        Analyzing your query
                                    </p>
                                    <div className="spinner">
                                        <div className="dot1"></div>
                                        <div className="dot2"></div>
                                        <div className="dot3"></div>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                );
            })}
        </div>
    );
}

export default QueryAns;
