import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { SearchBar } from "../../components";
import QueryAns from "../../components/queryAns/QueryAns";
import "./chat.css";
import videoSrc from "../../assets/videos/intro.mp4"; 
import namasteGirl from "../../assets/images/nam.png";
import homeIcon from "../../assets/images/homeIcon.png";

interface ChatProps {
  isChatbotButtonClicked: boolean;
}

const Chat: React.FC<ChatProps> = ({ isChatbotButtonClicked }) => {
  const [inputValue, setInputValue] = useState<string>("");
  const [isVideoFinished, setIsVideoFinished] = useState<boolean>(false);
  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);
  const navigate = useNavigate();
  const videoRef = useRef<HTMLVideoElement>(null);
  const [hasSpoken, setHasSpoken] = useState<boolean>(false);
  const [color, setColor] = useState<string>("white");

  const paragraph = "नमस्ते, मैं स्मार्ट दीदी हूं, आपकी Jeevika चैटबॉट। मैं Jeevika और इसके थीम पर डिजिटल सॉल्यूशन से संबंधित प्रश्नों का उत्तर देने के लिए प्रशिक्षित हूं, जिसमें IBCB, वित्तीय समावेशन, कृषि, गैर-कृषि, पशुधन, नौकरियां, HNS, सामाजिक विकास से जुड़े सवाल पूछ सकते हैं।"; // Hindi paragraph text here

  const handleBackClick = () => {
    navigate('/');
  };

  const handleVideoEnd = () => {
    setIsVideoFinished(true);
  };

  const handleSkipVideo = () => {
    if (videoRef.current) {
      videoRef.current.pause();
      setIsVideoFinished(true);
    }
  };

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };
  useEffect(() => {
    const handleSkip = () => {
      handleSkipVideo();
    };

    if (!isVideoFinished) {
      // Add event listeners for key press and mouse click
      window.addEventListener("keydown", handleSkip);
      window.addEventListener("mousedown", handleSkip);
    }

    return () => {
      // Clean up event listeners on component unmount or when video is finished
      window.removeEventListener("keydown", handleSkip);
      window.removeEventListener("mousedown", handleSkip);
    };
  }, [isVideoFinished]);

  useEffect(() => {
    // Your existing code for speech synthesis
  }, [isChatbotButtonClicked, hasSpoken, paragraph]);

  return (
    <div className="chat-conversation" style={{ backgroundColor: color }}>
      <div className="chatbot-header">
        <img src={namasteGirl} alt="Namaste Girl" className="namaste-girl" />
        <h3 className='chatbot-header-txt'>Smart DIDI</h3>
        <div className="color-options">
          <button onClick={() => setColor("#255570")}></button>
          <button onClick={() => setColor("#1A3636")}></button>
          <button onClick={() => setColor("#36BA98")}></button>
          <button onClick={() => setColor("#000000")}></button>
        </div>
      </div>

      {!isVideoFinished && (
        <div className="video-container">
          <video ref={videoRef} width="100%" height="auto" controls onEnded={handleVideoEnd} autoPlay>
            <source src={videoSrc} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
          {/* <button className="skip-button" onClick={handleSkipVideo}>Press Any Key to Skip</button> */}
          <p className="skip-text">Press any key or click anywhere to skip</p> {/* Updated instruction text */}
        </div>
      )}

      {isVideoFinished && (
        <>
          <QueryAns id='queryAns-main-container'/>
          <div className="chatbot-footer">
            <button className="home-btn" onClick={handleBackClick}>
              <img src={homeIcon} alt="Home" className="home-icon" />
            </button>
            <SearchBar
              id={"searchbar"}
              inputValue={inputValue}
              setInputValue={setInputValue}
            />
            {/* Hamburger button near the search box */}
            <button className="hamburger-btn" onClick={toggleMenu}>
              <div className="hamburger-line"></div>
              <div className="hamburger-line"></div>
              <div className="hamburger-line"></div>
            </button>
          </div>
        </>
      )}

      {/* Bottom to top dropdown menu */}
      {isMenuOpen && (
        <div className="dropdown-menu">
          <ul>
            <li><a href="https://brlps.in/" target="_blank" rel="noopener noreferrer">Brlps</a></li>
            <li><a href="https://mis.brlps.in/bireport/bireports.aspx" target="_blank" rel="noopener noreferrer">CEO Dashboard</a></li>
            <li><a href="https://app.powerbi.com/view?r=eyJrIjoiMjAwYjU5MjUtZDk0Mi00NzM0LWIzNzgtZjlhNDk1ZjkxMjI5IiwidCI6ImE3NDRlNmIxLTI1ODAtNDk5MS04YjMyLTY1MWY3ZTJkZWY0NiJ9" target="_blank" rel="noopener noreferrer">CLF 360</a></li>
          </ul>
        </div>
      )}
    </div>
  );
};

export default Chat;
