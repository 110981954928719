import React from "react";
import nam from "../../assets/images/nam.png";
import newIcon from "../../assets/images/newIcon.png"; // Import your new icon image
import { Header, ImgBtn } from "../../components";
import { useNavigate } from "react-router-dom";
import "./home.css";

interface HomeProps {
  setIsChatbotButtonClicked: React.Dispatch<React.SetStateAction<boolean>>;
}

const Home: React.FC<HomeProps> = ({ setIsChatbotButtonClicked }) => {
  const navigate = useNavigate();

  function handleAskDidiBtn() {
    setIsChatbotButtonClicked(true);
    navigate('/chat');
  }

  // function handleNewIconClick() {
  //   window.location.href = "https://dms.geotechnosoft.com/chat";
  // }

  return (
    <div className="home-page">
      <Header />

      {/* Dialogue Box */}
      <div className="dialogue-box">
        <p>Click Here to Ask</p>
      </div>

      <ImgBtn
        id="chatbotButton"
        imgSrc={nam}
        handleImgBtnClick={handleAskDidiBtn}
      />

      {/* New Icon
      <div className="new-icon-container">
        <ImgBtn
          id="newIconButton"
          imgSrc={newIcon}
          handleImgBtnClick={handleNewIconClick}
        />
        <p className="icon-label">Search Documents</p>
      </div> */}
    </div>
  );
};

export default Home;
